import React from 'react';
import { ReactComponent as MixComponentsIcon } from '../../assets/mix-components-icon.svg';
import { ReactComponent as DriversIcon } from '../../assets/drivers-icon.svg';
import { ReactComponent as VehicleTypesIcon } from '../../assets/vehicle-types-icon.svg';
import { ReactComponent as OrderStatusesIcon } from '../../assets/order-statuses-icon.svg';
import { ReactComponent as ShippingMethodsIcon } from '../../assets/shipping-methods-icon.svg';
import { ReactComponent as CreditCodesIcon } from '../../assets/credit-codes-icon.svg';
import { ReactComponent as UnloadingMethodsIcon } from '../../assets/unloading-methods-icon.svg';
import { ReactComponent as TruckIcon } from '../../assets/driver-truck.svg';
import { ReactComponent as OrdersIcon } from '../../assets/orders-icon.svg';
import { ReactComponent as SalesQuotesIcon } from '../../assets/sales-quotes-icon.svg';
import { ReactComponent as InventoryProductsIcon } from '../../assets/inventory-products-icon.svg';
import { ReactComponent as TrailerTypesIcon } from '../../assets/trailer-types-icon.svg';
import { ReactComponent as DevicesIcon } from '../../assets/devices-icon.svg';
import { ReactComponent as BulkMaterialsIcon } from '../../assets/bulk-materials-icon.svg';
import { ReactComponent as SalesSettingsIcon } from '../../assets/sales-settings-icon.svg';
import SvgIcon from '@mui/material/SvgIcon';

const CustomIcons = {
  MixComponentsIcon: props => <SvgIcon inheritViewBox component={MixComponentsIcon} {...props} />,
  DriversIcon: props => <SvgIcon inheritViewBox component={DriversIcon} {...props} />,
  VehicleTypesIcon: props => <SvgIcon inheritViewBox component={VehicleTypesIcon} {...props} />,
  OrderStatusesIcon: props => <SvgIcon inheritViewBox component={OrderStatusesIcon} {...props} />,
  ShippingMethodsIcon: props => <SvgIcon inheritViewBox component={ShippingMethodsIcon} {...props} />,
  CreditCodesIcon: props => <SvgIcon inheritViewBox component={CreditCodesIcon} {...props} />,
  UnloadingMethodsIcon: props => <SvgIcon inheritViewBox component={UnloadingMethodsIcon} {...props} />,
  TruckIcon: props => <SvgIcon inheritViewBox component={TruckIcon} {...props} />,
  OrdersIcon: props => <SvgIcon inheritViewBox component={OrdersIcon} {...props} />,
  SalesQuotesIcon: props => <SvgIcon inheritViewBox component={SalesQuotesIcon} {...props} />,
  InventoryProductsIcon: props => <SvgIcon inheritViewBox component={InventoryProductsIcon} {...props} />,
  TrailerTypesIcon: props => <SvgIcon inheritViewBox component={TrailerTypesIcon} {...props} />,
  DevicesIcon: props => <SvgIcon inheritViewBox component={DevicesIcon} {...props} />,
  BulkMaterialsIcon: props => <SvgIcon inheritViewBox component={BulkMaterialsIcon} {...props} />,
  SalesSettingsIcon: props => <SvgIcon inheritViewBox component={SalesSettingsIcon} {...props} />,
};

export default CustomIcons;
